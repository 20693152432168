module.exports = {
  defaultLanguage: 'en',
  locales: {
    en: 'en',
    es: 'es',
    fr: 'fr',
    pt: 'pt',
    br: 'br',
    nl: 'nl',
    de: 'de'
  }
};
