import Accordion from './accordion';
import Alert from './alert';
import Button from './button';
import Checkbox from './checkbox';
import FormLabel from './form-label';
import Input from './input';
import Link from './link';
import RadioCard from './radio-card';
import Select from './select';
import Tabs from './tabs';
import Tag from './tag';
import Textarea from './textarea';
import Tooltip from './tooltip';

export default {
  Accordion,
  Alert,
  Button,
  Checkbox,
  FormLabel,
  Input,
  Link,
  RadioCard,
  Select,
  Tabs,
  Tag,
  Textarea,
  Tooltip,
};
