export default {
  baseStyle: {
    container: {
      bg: '#FDEBEB',
      borderRadius: 'md',
      py: 1.5,
    },
    description: {
      color: 'critical.80',
      fontSize: 2,
      lineHeight: ['140%', null, null, '130%'],
      textAlign: 'center',
    },
  },
};
