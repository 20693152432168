import { createBreakpoints } from '@chakra-ui/theme-tools';

export default createBreakpoints({
  base: '0px',
  sm: '320px',
  md: '768px',
  lg: '1280px',
  xl: '1440px',
  '2xl': '1920px',
});
