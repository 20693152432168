export default {
  px: '1px',
  0: '0',
  0.25: '0.2rem',
  0.5: '0.4rem',
  1: '0.8rem',
  1.5: '1.2rem',
  2: '1.6rem',
  3: '2.4rem',
  4: '3.2rem',
  5: '4rem',
  6: '4.8rem',
  7: '5.6rem',
  8: '6.4rem',
  9: '7.2rem',
  10: '8rem',
  11: '8.8rem',
  12: '9.6rem',
  13: '10.4rem',
  14: '11rem',
  15: '11.8rem',
};
