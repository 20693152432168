export default {
  sizes: {
    md: {
      fontSize: '1.6rem',
    },
  },
  variants: {
    flushed: {
      borderBottom: '0.2rem solid',
      borderColor: 'darkBlue.100',
    },
  },
  defaultProps: {
    variant: 'flushed',
  },
};
