export default {
  fonts: {
    accent: 'Gelion',
    heading: 'Gelion',
    body: 'Roboto',
  },
  fontSizes: [
    '1.2rem',
    '1.4rem',
    '1.6rem',
    '1.8rem',
    '2rem',
    '2.4rem',
    '2.6rem',
    '3.2rem',
    '3.8rem',
    '4rem',
    '5.6rem',
  ],
  fontWeights: {
    regular: 400,
    normal: 500,
    semibold: 600,
    bold: 700,
  },
  textStyles: {
    h1: {
      fontSize: [6, null, null, 9],
      fontWeight: 600,
    },
    jumbo: {
      fontFamily: 'heading',
      fontWeight: 'semibold',
      letterSpacing: '-0.5px',
      fontSize: [8, null, null, 10],
      lineHeight: ['116%', null, null, '106%'],
      color: 'darkBlue.100',
    },
    title1: {
      fontFamily: 'heading',
      fontWeight: 'semibold',
      letterSpacing: '-0.5px',
      fontSize: [6, null, null, 9],
      lineHeight: '120%',
      color: 'darkBlue.100',
    },
    title2: {
      fontFamily: 'heading',
      fontWeight: 'semibold',
      letterSpacing: '-0.5px',
      fontSize: [5, null, null, 7],
      lineHeight: '120%',
      color: 'darkBlue.100',
    },
    body1: {
      fontFamily: 'heading',
      fontWeight: 'semibold',
      letterSpacing: '-0.5px',
      fontSize: [4, null, null, 5],
      lineHeight: '120%',
    },
    body2: {
      fontFamily: 'body',
      fontWeight: ['semibold', null, null, 'bold'],
      fontSize: [3, null, null, 4],
      lineHeight: ['120%', null, null, '130%'],
    },
    body3: {
      fontFamily: 'body',
      fontWeight: 'regular',
      fontSize: [3, null, null, 4],
      lineHeight: ['140%', null, null, '150%'],
    },
    body4: {
      fontFamily: 'body',
      fontWeight: 'regular',
      fontSize: 2,
      lineHeight: '140%',
    },
    body5: {
      fontFamily: 'body',
      fontWeight: 'regular',
      fontSize: 1,
      lineHeight: ['140%', null, null, '130%'],
    },
    body6: {
      fontFamily: 'body',
      fontWeight: 'regular',
      fontSize: 0,
      lineHeight: '130%',
    },
    eyebrow: {
      fontFamily: 'body',
      fontWeight: 'bold',
      fontSize: 1,
      lineHeight: '150%',
      textTransform: 'uppercase',
    },
  },
};
